import Link from 'next/link'

export default function Footer() {
    return (
        <header className="navbar border-bottom" style={{ height: '3rem' }}>
            <div className="container-sm">
                <Link href="/">
                    <a className="d-flex text-decoration-none logo">
                        <img
                            src={require('/public/img/logo.svg')}
                            width={32.89}
                            height={32}
                            alt="Compare Dates logo"
                        />
                        <span
                            className="ms-2 align-self-center fw-bold"
                            style={{ letterSpacing: '-0.8px' }}
                        >
                            <span className="text-dark">compare</span>
                            <span className="text-muted" style={{ marginLeft: '2px' }}>
                                dates
                            </span>
                        </span>
                    </a>
                </Link>
            </div>
        </header>
    );
}
