import Head from 'next/head'
import Header from './Header'
import Footer from './Footer'

export default function Layout({ children }: any) {
  return (
    <div className="d-flex flex-column h-100">
      <Head>
        <meta name="description" content="Online date comparison. Supports multiple formats and time zones." />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <link rel="apple-touch-icon" sizes="180x180" href={require('/public/img/favicon/apple-touch-icon.png')} />
        <link rel="icon" type="image/png" sizes="32x32" href={require('/public/img/favicon/favicon-32x32.png')} />
        <link rel="icon" type="image/png" sizes="16x16" href={require('/public/img/favicon/favicon-16x16.png')} />
        <link rel="manifest" href="/img/favicon/site.webmanifest" />
        <link rel="mask-icon" href={require('/public/img/favicon/safari-pinned-tab.svg')} color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        {/* Global Site Tag (gtag.js) - Google Analytics */}
          <script
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-NQ82Z73');
            `,
            }}
          />
      </Head>
      <Header />
      <main className="container-sm mt-3 d-flex flex-column main">{children}</main>
      <Footer />
      <noscript className="d-flex text-center w-100 align-items-center">
          <div className="ms-auto me-auto">
              <a href="http://enable-javascript.com/" className="d-inline-block mb-3">
                Please enable JavaScript to use this web application.
              </a>
          </div>
      </noscript>
    </div>
  );
}
